import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, DateTimeField, Field, TemperatureField } from '../../components/DataTable';

function IotTable({ history, deals, total = 0, query = {}, onFilter, isLoading }) {
  const [t] = useTranslation('COMMON');
  const { offset } = query;

  return (
    <DataTable
      offset={offset}
      total={total}
      data={deals}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
    >
      <DateTimeField title={t('DATE')} format="DD/MM/YYYY HH:mm:ss" source="date" className="close-date" />
      <Field title={t('DEVICE')} source="device" />
      <TemperatureField title={t('TEMPERATURE')} source="temperature" />
    </DataTable>
  )
}

export default withRouter(IotTable)
