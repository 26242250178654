import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Button, CardHeader,
} from 'reactstrap';
import Menu from '../../components/Menu';
import './ContactDetail.scss';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ReactComponent as IconContacts } from '../../images/icn_contatti.svg';
import backIcon from '../../images/icn-back.svg';
import ActivityButtonGroup from '../../components/ActivityButtonGroup';
import ModalConfirm from '../../components/ModalConfirm';
import EditableLabel from '../../components/EditableLabel';
import './ContactDetail.scss';
import CardActivities from '../../components/CardActivities';
import { getMailtoAnchor } from '../../lib/helpers/mailto';
import { Link } from 'react-router-dom';
import SearchModal from '../../components/SearchModal/SearchModal';
import CompaniesAutosuggest from '../../components/CompaniesAutosuggest';
import { ButonTrash } from '../../components/ButtonTrash/ButtonTrash';
import PriceListAttachments from '../../components/PriceLists';
import { fetchAttachments } from '../../lib/fetch/attachments';
import { useAuth } from '../../components/Auth';

function ContactDetailPresentational({ contact, history, onDelete, onSave, onSaveActivity, onDeleteActivity, activities = [] }) {
  const [t] = useTranslation('CONTACT_DETAIL');
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [priceList, setPriceList] = useState([]);
  const [{ token, profile } = {}] = useAuth();
  
  const { first_name, last_name, phone, cell_phone, email, company_id, company_name } = contact;

  const fetchPriceList = async () => {
    
    const attachments = await fetchAttachments({ token, contact_id: contact.id });
    if (!attachments.error) {
      setPriceList(attachments.data);
    }
  }

  useEffect(() => {
    contact.id && fetchPriceList();
    //eslint-disable-next-line
  }, [contact.id])
  
  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('CONTACTS:TITLE')}
            icon={<IconContacts fill='#FF6B38' />}
          />

          <div className="row m-b-30">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <Button className="btn-plus" onClick={() => history.goBack()}>
                <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
              </Button>
            </div>
            <div className="edit-element">
              <ModalConfirm text={t('DELETE_REQUEST')} onConfirm={onDelete}>
                <ButonTrash />
              </ModalConfirm>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Card className="sales-card">
                <CardHeader>
                  <h3 style={{ marginBottom: '0' }}>
                    {first_name} {last_name}
                    {
                      last_name &&
                      <Link to={`/deals?search=${first_name} ${last_name}`} className="btn contact-deals-link">
                        {t('COMMON:DEALS')}
                      </Link>
                    }
                  </h3>
                </CardHeader>
                <CardBody className="scrollable">
                  <ActivityButtonGroup onSave={onSaveActivity} user_id={profile.id} />
                  <div className="cont-info cont-info-contact">
                    <div className="row">
                      <div className="col-6">
                        <p>{t('FIRST_NAME')}</p>
                      </div>
                      <div className="col-6 text-right">
                        <EditableLabel allowEmpty={true} type="text" value={first_name} onSave={(v) => onSave({ first_name: v })}>
                          <p>{first_name ? <b>{first_name}</b> : <i>{t('INSERT_A_VALUE')}</i>}</p>
                        </EditableLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('LAST_NAME')} *</p>
                      </div>
                      <div className="col-6 text-right">
                        <EditableLabel type="text" value={last_name} onSave={(v) => onSave({ last_name: v })}>
                          <p><b>{last_name}</b></p>
                        </EditableLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{(email && getMailtoAnchor(email, <span><i className="fas fa-envelope action-url" /> {t('EMAIL')}</span>)) || <span>{t('EMAIL')}</span>}</p>
                      </div>
                      <div className="col-6 text-right">
                        <EditableLabel allowEmpty={true} type="email" value={email} onSave={(v) => onSave({ email: v })}>
                          <p>{email ? <b>{email}</b> : <i>{t('INSERT_A_VALUE')}</i>}</p>
                        </EditableLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>
                          {
                            phone ?
                              <a href={`tel:${phone}`}><i className="fas fa-phone action-url"></i> {t('PHONE')}</a>
                              :
                              <span>{t('PHONE')}</span>
                          }
                        </p>
                      </div>
                      <div className="col-6 text-right">
                        <EditableLabel allowEmpty={true} type="text" value={phone} onSave={(v) => onSave({ phone: v })}>
                          <p>{phone ? <b>{phone}</b> : <i>{t('INSERT_A_VALUE')}</i>}</p>
                        </EditableLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>
                          {
                            cell_phone ?
                              <a href={`tel:${cell_phone}`}><i className="fas fa-phone action-url"></i> {t('CELL_PHONE')}</a>
                              :
                              <span>{t('CELL_PHONE')}</span>
                          }
                        </p>
                      </div>
                      <div className="col-6 text-right">
                        <EditableLabel allowEmpty={true} type="text" value={cell_phone} onSave={(v) => onSave({ cell_phone: v })}>
                          <p>{cell_phone ? <b>{cell_phone}</b> : <i>{t('INSERT_A_VALUE')}</i>}</p>
                        </EditableLabel>
                      </div>
                    </div>
                    
                    <div className="row align-items-center">
                      <div className="col-4">
                        <p>{t('COMMON:COMPANY')}:</p>
                      </div>
                      <div className="col-8">
                        <div className="row-company-info">
                          <div className="search-value" onClick={() => history.push(`/company-detail/${company_id}/info`)}>
                            {company_name ? <b>{company_name}</b> : <i>{t('DEAL_DETAIL:MODIFY_A_COMPANY')}</i>}
                          </div>
                          <SearchModal
                            title={t('DEAL_DETAIL:MODIFY_A_COMPANY')}
                            searchLabel={t('COMMON:SEARCH')}
                            open={openCompanyModal}
                            toggle={() => setOpenCompanyModal(false)}
                            onConfirm={result => result && result.id && onSave({ company_id: result.id })}
                            onDelete={() => onSave({ company_id: null, company_name: null })}
                          >
                            <CompaniesAutosuggest onChange={() => console.log('ok')}/>
                          </SearchModal>
                        
                          <Button className="btn-plus btn-company" onClick={() => !openCompanyModal && setOpenCompanyModal(true)}>
                            {t('COMMON:SELECT_COMPANY')}
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center mt-4">
                      <div className='col-12'>
                        <PriceListAttachments
                          priceList={priceList}
                          contact_id={contact.id}
                          title={t('CUSTOM_PRICE_LIST')}
                          onSuccessCallback={fetchPriceList} /> 
                          </div>
                      </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <CardActivities
                activitiesData={activities}
                onSaveActivity={onSaveActivity}
                onDeleteActivity={onDeleteActivity}
              />
            </div>
          </div>

        </section>
        <Footer />
      </div>
      <BurgerMenu />
    </div >
  );
}

export default ContactDetailPresentational;
