import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatNumber } from '../../lib/helpers/formatters';

export default (props) => {
  const { dealsBudgetsRatio } = props
  const [t] = useTranslation('BUDGET_OFFER_REPORT');

  const data = dealsBudgetsRatio?.map((d) => ({ ...d, deals: formatNumber(d.deals, 2), budget: formatNumber(d.budget, 2), }))

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip formatter={value => formatCurrency(value)} />
        <Legend />
        <Line type="monotone" dataKey="deals" name={t('DEALS')} stroke="#2F4491" />
        <Line type="monotone" dataKey="budget" name={t('BUDGET')} stroke="#FF6B38" />
      </LineChart>
    </ResponsiveContainer>
  );
}
