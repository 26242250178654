import React from 'react';
import './kpi.scss';

export const Kpi = ({ title, value }) => {
  return (
      <div className='kpi-box'>
        <span className='kpi-title'>{title?.toString()}</span>
        <span className='kpi-value'>{value?.toString()}</span>
      </div>
  );
};
