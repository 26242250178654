import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { formatNumber } from '../../lib/helpers/formatters';

export default (props) => {
  const { costCentres } = props;
  const [t] = useTranslation();

  const data = costCentres?.map((c) => ({ ...c, value: formatNumber(c.value, 2) }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      {
        (data && Array.isArray(data) && data.length > 0) ?
          <PieChart margin={{ bottom: 20 }}>
            <Pie
              data={data}
              dataKey="value"
              name="label"
            >
              {
                data.map((entry, index) => <Cell key={index} fill={entry.color} />)
              }
            </Pie>
            <Legend payload={data.map((costCenter, index) => ({ value: t(`COST_CENTERS:${costCenter.label}`), type: 'circle', id: index, color: costCenter.color }))} />
          </PieChart>
          :
          <div className="u-center-center">{t('COMMON:NO_DATA')}</div>
      }
    </ResponsiveContainer>
  );
};
