import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import { ReactComponent as IconAProducts } from '../../images/icn_products.svg';
import Filters from '../../components/Filters'
import { UncontrolledCollapse, Button, Card, CardBody } from 'reactstrap'
import plusIcon from '../../images/icn-plus.svg'
import Footer from '../../components/Footer'
import BurgerMenu from '../../components/BurgerMenu'
import Spinner from '../../components/Spinner';
import { FILTERS } from '../../constants/filters';
import { DataTable, Field } from '../../components/DataTable';
import ProductModal from './ProductModal';

function ProductsPresentational(props) {
	const [t] = useTranslation('PRODUCTS');
	const {
    history,
		products,
		total = 0,
		query = {},
		onFilter,
		onCreateProduct,
		onSaveProduct,
		onDeleteProduct,
		isLoading = false,
		isAdding = false,
	} = props;

  const productColumns = [
    { title: t('COLUMN_NAME'), source: "productName" },
    { title: t('COLUMN_LINE'), source: "productLine" },
    { title: t('COLUMN_CAPACITY'), source: "capacity" }
  ]

  const modalRef = useRef();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState();
  const toggle = () => setOpen(!open);

  const openModal = (product = {}) => {
    setProduct(product)
    toggle()
    setTimeout(() => modalRef.current.resetForm(), 200)
  }
 
	return (
		<div className="App">
      <Menu />
      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconAProducts fill='#FF6B38' />}
          />

          <div className="row">
            <div className="col-6">
              <Button className="btn-collapse" id="toggler" style={{ marginBottom: '1rem' }}>
                {t('COMMON:FILTER_BY')} <i className="fas fa-filter"></i>
              </Button>
            </div>
            <div className="col-6 text-right">
              {
                isAdding ?
                  <div>
                    <Spinner />
                  </div>
                  :
                  <Button
                    className="btn-plus"
                    onClick={openModal}
                  >
                    <img src={plusIcon} alt="" /> {t('ADD_PRICE_LIST')}
                  </Button>
              }
            </div>
          </div>

          <UncontrolledCollapse toggler="#toggler">
            <div className="m-t-20">
              <Filters
                query={query}
                onFilter={onFilter}
                // TEMPORARY -> sample filters list 
                list={[FILTERS.COMPANY_ID, FILTERS.CONTACT_ID, FILTERS.USER_ID, FILTERS.SEARCH]} 
              />
            </div>
          </UncontrolledCollapse>

          <hr />

          <Card>
            <CardBody>              
              <DataTable
                query={query}
                total={total}
                data={products}
                isLoading={isLoading}
                loadingText={t('COMMON:LOADING')}
                emptyText={t('COMMON:NO_DATA')}
                onFilter={onFilter}
                onRowClick={({ id } = {}) => history.push(`/product/${id}`)}
              >
                {productColumns?.map(({title, source}, index) => (
                  <Field key={index} title={title} source={source} />
                ))}                
              </DataTable>              
            </CardBody>
          </Card>
        </section>

        
        <ProductModal
          history={history}
          ref={modalRef}
          open={open}
          toggle={toggle}
          product={product}
          onSubmit={values => {
            const { id } = product || {}
            id ? onSaveProduct(id, values).then(toggle) : onCreateProduct(values).then(toggle)
          }}
          onDeleteProduct={id => onDeleteProduct(id).then(toggle)}
        />
        <Footer />
      </div>

      <BurgerMenu />
    </div >		
	);
}

export default withRouter(ProductsPresentational);
