import axios from 'axios';
import qs from 'qs';

export const fetchAttachments = async (params = {}) => {
  const {
    token,
    product_id,
    deal_id,
    contact_id
  } = params;

  const queryString = { product_id, deal_id, contact_id };

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/attachments?${qs.stringify(queryString)}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const uploadAttachment = async ({ token, file, deal_id } = {}) => {
  const data = new FormData();
  data.append('file', file)

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/attachments?${qs.stringify({ 
        deal_id,
      })}`,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
      data
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    return { error };
  }
};

export const editAttachment = async ({ token, deal_id, attachmentId, label } = {}) => {

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/attachments/${attachmentId}}`,
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      data: {
        label,
        deal_id
      }
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    return { error };
  }
};

export const signedUrl = async ({ token, attachmentId } = {}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/attachments/${attachmentId}/signedUrl`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    return { error };
  }
};

export const deleteAttachment = async ({ token, attachmentId } = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/attachments/${attachmentId}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    return { error };
  }
};