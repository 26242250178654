import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import { useAlert } from 'react-alert';
import { useLoading } from '../../components/Loading'
import { useAuth } from '../../components/Auth';
import { saveProduct, deleteProduct, createProduct, fetchProducts } from '../../lib/fetch/products';
import ProductsPresentational from './ProductsPresentational';

function Products(props) {
	const { history } = props;
	const [t] = useTranslation();
	const alert = useAlert();
	const [isAdding, setIsAdding] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const { showLoading, hideLoading } = useLoading();
	const [productsData, setProductsData] = useState([]);
	const [auth = {}] = useAuth();
	
	const { token } = auth;
	
	// TO DEV - define filters list for Products
    const [query, setQuery] = useQueryParams({
        offset: NumberParam,
        search: StringParam,
        type: StringParam,
        contact: StringParam
    });
    const { offset, search, type, contact } = query;

	useEffect(() => {
		const fetch = async () => {
		  setIsLoading(true);
		  const response = await fetchProducts({token});
		  const products = response.data;
		  if (Array.isArray(products)) {
		  	setProductsData(products);
		  }
		  setIsLoading(false);
		};
	
		fetch();
	  }, [alert, t, token, offset, search, type, contact]);
	  
	const onCreateProduct = async (values) => {
		showLoading();
		const { productName, productLine, contact, unitPrice } = values;
		
		setIsAdding(true);
		const { error: createProductError, data } = await createProduct({ data: { productName, productLine, contact, unitPrice }, token });
		setIsAdding(false);
		if (createProductError) {
			const { response: { data: { message = '' } = {} } = {} } = createProductError;
			const errorMessage = message === 'product-exist' ? '_ERROR_:_PRODUCT_EXIST' : t('COMMON:GENERIC_ERROR');
	  
			hideLoading();
			alert.error(errorMessage);
			return;
		}

		await fetch({ token });
		hideLoading();
			history.push(`/product/${data.id}`);
	}

	const onSaveProduct = async (id, values) => {
		showLoading();
		const { error } = await saveProduct({ token, id, data: values });

		if (error) {
			hideLoading();
			alert.error(t('COMMON:GENERIC_ERROR'));
			return;
		}

		await fetch({ token });
		hideLoading();
		alert.success(t('COMMON:SAVE_SUCCESS'));
	};

	const onDeleteProduct = async (id) => {
		showLoading();
		const { error } = await deleteProduct({ id, token });
	
		if (error) {
		  hideLoading();
		  alert.error(t('COMMON:GENERIC_ERROR'));
		  return;
		}
	
		await fetch({ token });
		hideLoading();
		alert.success(t('COMMON:DELETE_SUCCESS'));
	  };

	return (
		<ProductsPresentational
			history={history}
			products={productsData}
			query={query}
			onFilter={setQuery}
			onCreateProduct={onCreateProduct}
			onSaveProduct={onSaveProduct}
			onDeleteProduct={onDeleteProduct}
			isLoading={isLoading}
			isAdding={isAdding}
			{...props}
		/>
	);
}

export default Products;
