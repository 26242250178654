import React from 'react';

function TemperatureField({ value }) {
  const numericValue = parseFloat(value);
  if (isNaN(numericValue)) return <span></span>;

  const style = numericValue >= 40 ? { color: 'red' } : {};

  return (
    <span style={style}>{numericValue.toFixed(2).replace('.', ',')}</span>
  );
}

export default TemperatureField;