import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, CustomInput } from 'reactstrap';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BurgerMenu from '../../components/BurgerMenu';
import { ReactComponent as IconAProducts } from '../../images/icn_products.svg';
import backIcon from '../../images/icn-back.svg';
import ModalConfirm from '../../components/ModalConfirm';
import EditableLabel from '../../components/EditableLabel';
import PriceListAttachments from '../../components/PriceLists';
import { ButonTrash } from '../../components/ButtonTrash/ButtonTrash';
import { fetchAttachments } from '../../lib/fetch/attachments';
import { useAuth } from '../../components/Auth';
import fetchProductLines from '../../lib/fetch/productLines';

function ProductDetailPresentational ({
  product,
  history,
  onDelete, 
  onSave
}) {
	const [t] = useTranslation('PRODUCT_DETAIL');
	const [{ token } = {}] = useAuth();
  const { 
		id,
		productLine,
		productName, 
		productDescription, 
		capacity,
		unitPrice,
		palletPrice,
		halfPalletPrice,
		boxPrice,
	} = product

  const [priceList, setPriceList] = useState([]);
  const [productLines, setProductLines] = useState([]);

  const fetchPriceList = async () => {
    const { error, data } = await fetchAttachments({ token, product_id: id });
    if (!error) {
      setPriceList(data);
    }
  }

  const getProductLines = async () => {
    const { error, data } = await fetchProductLines({ token });
    if (!error) {
      console.log(data)
      setProductLines(data);
    }
  }

  useEffect(() => {
    if (id) {
      fetchPriceList();
      getProductLines();
    }
    //eslint-disable-next-line
  }, [id])

  const productFields = [
    { field: productName, label: t('FIELD_NAME'), type: 'text', key: 'productName' },
    { field: productDescription, label: t('FIELD_DESCRIPTION'), type: 'text', key: 'productDescription' },
    { field: capacity, label: t('FIELD_CAPACITY'), type: 'number', key: 'capacity' },
    { field: unitPrice, label: t('FIELD_UNIT_PRICE'), type: 'number', key: 'unitPrice' },
    { field: palletPrice, label: t('FIELD_PALET_PRICE'), type: 'number', key: 'palletPrice' },
    { field: halfPalletPrice, label: t('FIELD_HALF_PALET_PRICE'), type: 'number', key: 'halfPalletPrice' },
    { field: boxPrice,  label: t('FIELD_BOX_PRICE'), type: 'number', key: 'boxPrice' },
  ];

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconAProducts fill='#FF6B38' />}
          />
          <div className="row m-b-30">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <Button className="btn-plus" onClick={() => history && history.goBack()}>
                <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
              </Button>
            </div>
            <div className="edit-element">
              <ModalConfirm text={t('DELETE_REQUEST')} onConfirm={onDelete}>
                <ButonTrash />
              </ModalConfirm>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Card className="sales-card">
                <CardHeader>
                  <h3 style={{ marginBottom: '0' }}>
                    {productName} | {productLines?.find(({ code }) => code === productLine)?.name}                    
                  </h3>
                </CardHeader>

                <CardBody>
                  <div className="cont-info cont-info-contact">                              
                    <div className="row">
                      <div className="col-6">
                        {t('FIELD_LINE')}
                      </div>
                      <div className="col-6 text-right">                                  
                      <CustomInput
                          id="productLine"
                          type="select"
                          name="productLine"
                          value={productLine}
                          onChange={(event) => onSave({ productLine: event.target.value })}
                        >
                          {productLines?.map((line, index) => (
                            <option key={index}  value={line.code}>{line.name}</option>
                          ))}
                        </CustomInput>
                      </div>
                    </div>

                  {productFields?.map(({ field, label, type, key }) => (
                      <div className="row" key={key}>
                        <div className="col-6">
                          <p>{label}</p>
                        </div>
                        <div className="col-6 text-right">
                          <EditableLabel
                            allowEmpty={true}
                            type={type}
                            value={field ?? ''}
                            onSave={(v) => onSave({ [key]: v })}
                          >
                            <p>{field ? <b>{field}</b> : <i>{t('COMMON:INSERT_A_VALUE')}</i>}</p>
                          </EditableLabel>
                        </div>
                      </div>
                    ))}  
                  </div>
                </CardBody>
              </Card>
            </div>            
            
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Card className="sales-card">
                <CardHeader>
                  <h3 style={{ marginBottom: '0' }}>
                    {t('PRICE_LIST_ASSOCIATION')}                   
                  </h3>
                </CardHeader>

                <CardBody>
                  <div className="cont-info cont-info-contact"> 
                    <div className="">
                      <PriceListAttachments 
                        priceList={priceList}
                        product_id={id}
                        title={`${t('PRICE_LIST_PRODUCT')}`}
                        onSuccessCallback={fetchPriceList}
                      />
                    </div> 
                  </div> 
                </CardBody>
              </Card>
            </div>            
              
          </div>
        </section>
        <Footer />
      </div>


      <BurgerMenu />
    </div >
  );
}

export default withRouter(ProductDetailPresentational);
