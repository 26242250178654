import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './ActivityButtonGroup.scss';
import { Button } from 'reactstrap';
import ActivityModal from './ActivityModal';

const TYPES = ['call', 'email', 'meeting'];

function ActivityButtonGroup({ onSave }) {
  const [t] = useTranslation('ACTIVITIES');
  const modalRef = useRef()
  const [type, setType] = useState(TYPES[1]);
  const [modal, setModal] = useState();

  const toggle = () => setModal(!modal);
  const onToggle = (type) => {
    setType(type);
    setModal(!modal);
  }

  const onSubmit = (values) => {
    onSave(values, type);
    setModal(!modal);
  }

  return (
    <div className="cont-btn-log">
      <div className="row">
        <div className="col-4">
          <Button className="btn-call" onClick={() => onToggle(TYPES[0])}>{t('COMMON:CALL_LOG')}</Button>
        </div>
        <div className="col-4">
          <Button onClick={() => onToggle(TYPES[1])}>{t('COMMON:EMAIL_LOG')}</Button>
        </div>
        <div className="col-4">
          <Button className="btn-meeting" onClick={() => onToggle(TYPES[2])}>{t('COMMON:MEETING_LOG')}</Button>
        </div>
      </div>

      <ActivityModal
        ref={modalRef}
        type={type}
        open={modal}
        toggle={toggle}
        onSubmit={onSubmit}
      />

    </div>
  );
}

export default ActivityButtonGroup;
