import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadAttachment, signedUrl, deleteAttachment } from './../../lib/fetch/attachments';
import AttachmentsPresentational from './AttachmentPresentational';
import { useAuth } from './../Auth'
import { useAlert } from 'react-alert';
import './Attachments.scss'

const Attachments = ({ attachments, deal_id, onSuccessCallback, attachmentLabel: currentAttachmentLabel }) => {
  const [t] = useTranslation();
  const [{ token } = {}] = useAuth();
  const [uploading, setUploading] = useState(false);
  const alert = useAlert();

  const onChangeInputFile = async (event) => {
    setUploading(true)

    const file = event.target.files[0]
    const { error } = await uploadAttachment({ token, file, deal_id, currentAttachmentLabel })

    setUploading(false)

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return
    }
    alert.success(t('COMMON:SAVE_SUCCESS'))

    if (typeof onSuccessCallback === 'function') {
      onSuccessCallback()
    }
  }

  const onDownload = async (attachmentId) => {

    const { error, data } = await signedUrl({ token, attachmentId })

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return
    }

    const { url } = data || {}
    window.open(url, '_blank')
  }

  const onDelete = async (attachmentId) => {

    const { error } = await deleteAttachment({ token, attachmentId })

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return
    }

    alert.success(t('COMMON:DELETE_SUCCESS'))

    if (typeof onSuccessCallback === 'function') {
      onSuccessCallback()
    }
  }

  return (
    <AttachmentsPresentational
      attachments={attachments}    
      deal_id={deal_id}  
      onChangeInputFile={onChangeInputFile}
      uploading={uploading}
      onDownload={onDownload}
      onDelete={onDelete}
    />
  )
}

export default Attachments;