import React from "react";
import ModalConfirm from "../ModalConfirm";
import download from '../../images/icn-download.svg';
import { useTranslation } from "react-i18next";
import { ButonTrash } from "../ButtonTrash/ButtonTrash";

export const PriceListRow = ({ priceList, onDownload, onDelete}) => {

  const { id, file_name } = priceList || {}

  const [t] = useTranslation('PRODUCT_DETAIL');

  return (
    <div className='attachments-row'>
      <div className="attachments-row-info">   
        <div className="actions--delete">
          <ModalConfirm
            className="attachments-modal-confirm"
            text={t('DEAL_DETAIL:ATTACHMENT_DELETE_REQUEST')}
            onConfirm={() => onDelete(id)}
          >
            <ButonTrash />
          </ModalConfirm>  
          {file_name}
        </div>     
           
        <div className="actions--upload">
          <div onClick={() => onDownload(id)} className="action-button">
            <img src={download} alt="" /> 
            <span>Download</span>
          </div>
        </div>
      </div>      
    </div>
  );
};