import moment from 'moment';

export const formatDate = date => date ? moment(date).format('DD-MM-YYYY') : '';

const { Intl } = window || {};
let locale;

if (Intl && typeof Intl === 'object') {
  const { language } = navigator || {};
  locale = language;
}

export const formatCurrency = (amount = 0) => {
  if (!locale) return amount;
  return new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(amount);
};


export const formatNumber = (value, decimals = 0) => {
  let numericValue;

  value = value?.toString();

  if (typeof value === 'string') {
    if (value.includes(',') && !value.includes('.')) {
      numericValue = parseFloat(value.replace(',', '.'));
    } else {
      numericValue = parseFloat(value.replace(/,/g, ''));
    }
  } else {
    numericValue = value;
  }

  if (isNaN(numericValue) || !isFinite(numericValue)) {
    return 0;
  }

  return parseFloat(numericValue?.toFixed(decimals));
};
