import axios from 'axios';
import qs from 'qs';

export const uploadPriceList = async ({ token, file, product_id, deal_id, contact_id } = {}) => {
  const data = new FormData();
  data.append('file', file)

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/attachments?${qs.stringify({ 
        product_id, 
        deal_id,
        contact_id,
      })}`,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
      data
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    return { error };
  }
};

export const signedUrl = async ({ token, priceListId, type } = {}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/attachments/${priceListId}/signedUrl?${qs.stringify({ type })}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    return { error };
  }
};

export const deletePriceList = async ({ token, priceListId } = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/attachments/${priceListId}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    return { error };
  }
};