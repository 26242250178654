import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useAuth } from '../../components/Auth';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import { ReactComponent as IconCompanies } from '../../images/icn_aziende.svg';
import { ReactComponent as IconContacts } from '../../images/icn_contatti.svg';
import { ReactComponent as IconDeal } from '../../images/icn_offerte.svg';
import { ReactComponent as IconActivities } from '../../images/icn_attività.svg';
import { ReactComponent as IconTemperature } from '../../images/icn-temperature.svg';
import { ReactComponent as IconUsers } from '../../images/icn_utenti.svg';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import { ReactComponent as IconAProducts } from '../../images/icn_products.svg';
import './LinkMenu.scss';

function LinkMenu({ match }) {
  const { url } = match || {};
  const [{ profile = {} } = {}] = useAuth();
  const { role } = profile;

  const getActive = (url = '', paths = []) => {
    return paths.some(path => url.startsWith(path)) ? 'active' : '';
  };

  return (
    <div>
      <Link to={'/dashboard'} className={getActive(url, ['/dashboard'])}>
        <IconDashboard className="hover-color" />
      </Link>
      <Link to={'/companies'} className={getActive(url, ['/companies', '/company-detail/'])}>
        <IconCompanies className="hover-color" />
      </Link>
      <Link to={'/contacts'} className={getActive(url, ['/contacts', '/contact-detail'])}>
        <IconContacts className="hover-color" />
      </Link>
      <Link to={'/deals'} className={getActive(url, ['/deals', '/deal-detail/'])}>
        <IconDeal className="hover-color" />
      </Link>
      <Link to={'/activities'} className={getActive(url, ['/activities', '/activity-detail/'])}>
        <IconActivities className="hover-color" />
      </Link>
      <Link  to={'/products'} className={getActive(url, ['/products', '/product/'])}>
        <IconAProducts className="hover-color" />
      </Link>
      {/* <Link to={'/temperature'} className={getActive(url, ['/temperature'])}>
        <IconTemperature className="hover-color" fill="#ffffff" />
      </Link> */}
      {
        role === 'admin' &&
        <Link to={'/users'} className={getActive(url, ['/users'])}>
          <IconUsers className="hover-color" />
        </Link>
      }
      <Link to={'/info'} className={getActive(url, ['/info'])}>
        <IconProfile className="hover-color" />
      </Link>
    </div>
  );
}

export default withRouter(LinkMenu);