import axios from 'axios';
import qs from 'qs';
// import temperatures from '../mocks/temperatures.json';

const fetchIot = async (params = {}) => {
  const {
    token,
    offset = 0,
    order = 'date',
    sort = 'desc',
    limit = 100,
  } = params;

  const queryString = { offset, limit, order, sort };

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/temperatures?${qs.stringify(queryString)}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }

  // function orderByDateDescending(jsonArray) {
  //   return jsonArray.sort(function (a, b) {
  //     var dateA = new Date(a.date);
  //     var dateB = new Date(b.date);
  //     return dateB - dateA;
  //   });
  // }

  // return {
  //   data: orderByDateDescending(temperatures),
  //   headers: {
  //     'x-total-count': temperatures.length,
  //   },
  // };
};

export default fetchIot;
