import React, { useState } from "react";
import { attachmentsLabels } from "../../constants/attachmentsLabels";
import ModalConfirm from "../ModalConfirm";
import SelectLabel from "../SelectLabel";

import { useTranslation } from "react-i18next";
import { ButonTrash } from "../ButtonTrash/ButtonTrash";

export const AttachmentRow = ({ attachment, onChangeSelectLabel, onDownload, onDelete}) => {

  const { id, file_name } = attachment || {}

  const [label, setLabel] = useState(attachment.label)

  const [t] = useTranslation();

  return (
    <div className='attachment-row'>
      <div className="attachment-row-info">
        <ModalConfirm
          className="attachment-modal-confirm"
          text={t('DEAL_DETAIL:ATTACHMENT_DELETE_REQUEST')}
          onConfirm={() => onDelete(id)}
          >
            <ButonTrash />
        </ModalConfirm>     
        <div>
          <span
            onClick={() => onDownload(id)} 
            className="attachment-link"
          >
              {file_name}
          </span>
        </div>  
      </div>
      <div className="attachment-row-label">
        <SelectLabel
            attributeName="quoteLabel"
            value={label}
            defaultLabel={'other'}
            handleChange={(event) => {setLabel(event.target.value); onChangeSelectLabel(id, event.target.value)}}
            attachmentsLabels={attachmentsLabels}
        />
      </div>
    </div>
  );
};