import itCommon from './it.json';
import enCommon from './en.json';
import itDashboard from '../pages/Dashboard/translations/it';
import enDashboard from '../pages/Dashboard/translations/en';
import itLogin from '../pages/Login/translations/it';
import enLogin from '../pages/Login/translations/en';
import itForgot from '../pages/Forgot/translations/it';
import enForgot from '../pages/Forgot/translations/en'
import itReset from '../pages/Reset/translations/it';
import enReset from '../pages/Reset/translations/en';
import itCompanies from '../pages/Companies/translations/it';
import enCompanies from '../pages/Companies/translations/en';
import itCompanyDetail from '../pages/CompanyDetail/translations/it';
import enCompanyDetail from '../pages/CompanyDetail/translations/en';
import itContacts from '../pages/Contacts/translations/it';
import enContacts from '../pages/Contacts/translations/en';
import itContactDetail from '../pages/ContactDetail/translations/it';
import enContactDetail from '../pages/ContactDetail/translations/en';
import itDeals from '../pages/Deals/translations/it';
import enDeals from '../pages/Deals/translations/en';
import itDealDetail from '../pages/DealDetail/translations/it';
import enDealDetail from '../pages/DealDetail/translations/en';
import itBudgetOffertReport from '../components/BudgetOfferReport/translations/it';
import enBudgetOffertReport from '../components/BudgetOfferReport/translations/en';
import itActivities from '../pages/Activities/translations/it';
import enActivities from '../pages/Activities/translations/en';
import itUsers from '../pages/Users/translations/it';
import enUsers from '../pages/Users/translations/en';
import itCostCenters from '../components/CostCenters/translations/it';
import enCostCenters from '../components/CostCenters/translations/en';
import itProducts from '../pages/Products/translations/it.json';
import enProducts from '../pages/Products/translations/en.json';
import itProductDetail from '../pages/ProductDetail/translations/it.json';
import enProductDetail from '../pages/ProductDetail/translations/en.json';

const resources = {
  it: {
    COMMON: itCommon,
    LOGIN: itLogin,
    FORGOT: itForgot,
    RESET: itReset,
    DASHBOARD: itDashboard,
    COMPANIES: itCompanies,
    COMPANYDETAIL: itCompanyDetail,
    CONTACTS: itContacts,
    CONTACT_DETAIL: itContactDetail,
    DEALS: itDeals,
    DEAL_DETAIL: itDealDetail,
    USERS: itUsers,
    BUDGET_OFFER_REPORT: itBudgetOffertReport,
    ACTIVITIES: itActivities,
    COST_CENTERS: itCostCenters,
    PRODUCTS: itProducts,
    PRODUCT_DETAIL: itProductDetail,
  },
  en: {
    COMMON: enCommon,
    LOGIN: enLogin,
    FORGOT: enForgot,
    RESET: enReset,
    DASHBOARD: enDashboard,
    COMPANIES: enCompanies,
    COMPANYDETAIL: enCompanyDetail,
    CONTACTS: enContacts,
    CONTACT_DETAIL: enContactDetail,
    DEALS: enDeals,
    DEAL_DETAIL: enDealDetail,
    USERS: enUsers,
    BUDGET_OFFER_REPORT: enBudgetOffertReport,
    ACTIVITIES: enActivities,
    COST_CENTER: enCostCenters,
    PRODUCTS: enProducts,
    PRODUCT_DETAIL: enProductDetail,
  },
};

export default resources;
