import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, CustomInput,
} from 'reactstrap';
import FormErrorMessage from '../../components/FormErrorMessage';
import useForm from 'react-hook-form';
import fetchProductLines from '../../lib/fetch/productLines';
import { useAuth } from '../../components/Auth';

function ProductModal({ open, toggle, onSubmit }, ref) {
    const [t] = useTranslation('PRODUCTS');
    const [{ token } = {}] = useAuth();
    const { handleSubmit, errors, reset, register } = useForm({});

    useImperativeHandle(ref, () => ({
        resetForm() {
            const { productName = '', productLine = '', unitPrice = 0, description = '' } = {}
            reset({ productName, productLine, unitPrice, description })
        }
    }));
    
    const [productLines, setProductLines] = useState([]);

  const getProductLines = async () => {
    const { error, data } = await fetchProductLines({ token });
    if (!error) {
      setProductLines(data);
    }
  }

  useEffect(() => {
    getProductLines();
    //eslint-disable-next-line
  }, [])
    
    return (
        <Modal isOpen={open} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {t('ADD_NEW_PRODUCT')}
          </ModalHeader>
          <ModalBody>
            <Form className="m-b-35" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>{t('FORM_LABEL_NAME')}</Label>
                    <Input
                      type="text"
                      name="productName"
                      innerRef={register({ required: t('COMMON:REQUIRED'), maxLength: { value: 45, message: 'Max 45 caratteri' } })}
                    />
                    <FormErrorMessage errors={errors} name="productName" />
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">              
                  <FormGroup>
                    <Label className='col-sm-12 pl-0'>{t('FORM_LABEL_LINE')}</Label>
                    <CustomInput
                      id ='productLine'
                      type="select"
                      name="productLine"
                      innerRef={register({ required: t('COMMON:REQUIRED') })}
                    >
                      {productLines?.map((line, index) => (
                        <option key={index} value={line.code}>{line.name}</option>
                      ))}
                      {/* // TEMPORARY -> Optional other/empty option */}           
                      <option value=''></option>
                    </CustomInput>
                    <FormErrorMessage errors={errors} name="productLine" />
                  </FormGroup>
                </div>
              </div>          
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>{t('FORM_LABEL_PRICE')}</Label>
                    <Input
                      type="number"
                      name="unitPrice"
                      innerRef={register({ required: t('COMMON:REQUIRED') })} 
                    />
                    <FormErrorMessage errors={errors} name="unitPrice" />
                  </FormGroup>
                </div>            
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label className="mr-2">{t('COMMON:DESCRIPTION')}</Label>
                    <Input
                      type="textarea"
                      name="description"
                      className=""
                      innerRef={register('description', {
                        required: t('COMMON:REQUIRED'),
                      })}
                    />                    
                    <FormErrorMessage errors={errors} name="description" />
                  </FormGroup>
                </div>            
              </div>
              
              <ModalFooter className="cont-btn-modalform">            
                <Button type="submit" color="primary">
                  {t('ADD_NEW_PRODUCT')}
                </Button>
                <Button color="secondary" onClick={toggle}>{t('COMMON:CANCEL')}</Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
    )
}

export default forwardRef(ProductModal);