import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, Field, CurrencyField, DateTimeField } from '../../components/DataTable';
import { BadgeField, StageField } from '../../components/DataTableFields';

function DealsTable({ history, deals, total = 0, query = {}, onFilter, isLoading }) {
  const [t] = useTranslation('DEALS');
  const { offset } = query;

  return (
    <DataTable
      offset={offset}
      total={total}
      data={deals}
      isLoading={isLoading}
      loadingText={t('COMMON:LOADING')}
      emptyText={t('COMMON:NO_DATA')}
      onFilter={onFilter}
      onRowClick={({ id } = {}) => history.push(`/deal-detail/${id}`)}
    >
      <CurrencyField title={t('COMMON:AMOUNT')} source="amount" className="u-text-right" />
      {/* <CreditTypeField title={t('COMMON:CREDIT_TYPE')} source="credit_type" /> */}
      <Field title={t('COMMON:OFFER_NAME')} source="name" />
      <BadgeField title={t('COMMON:FUTURE_ACTIVITIES')} source="activities.toDoCounter" />
      <Field title={t('COMMON:COMPANY')} source="company_name" />
      <Field title={t('COMMON:CONTACT')} source="contact_name" />
      <Field title={t('COMMON:USER')} source="user_name" />
      <Field title={t('COMMON:SUCCESS_RATE')} source="success_rate" />
      <DateTimeField title={t('COMMON:CLOSING_DATE')} format="DD/MM/YYYY" source="closing_date" className="close-date" />
      <StageField title={t('COMMON:OFFER_STATUS')} source="stage" />
      {/* <OfferTypeField title={t('COMMON:OFFER_TYPE')} source="offer_type" /> */}
    </DataTable>
  )
}

export default withRouter(DealsTable)
