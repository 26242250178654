import React from 'react';
import { useTranslation } from 'react-i18next';
import './SelectLabel.scss';

const SelectLabel = ({ value, defaultLabel, handleChange, attachmentsLabels, attributeName }) => {
    const [t] = useTranslation();

  return (
    <select
      name={attributeName}
      defaultLabel={defaultLabel}
      value={value}
      onChange={handleChange}
      className="custom-select"
    >
      {attachmentsLabels && attachmentsLabels.map((option, i) => (
        <option key={i} value={option.value}>
          {t(option.label)}
        </option>
      ))}
    </select>
  );
};

export default SelectLabel;
