import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import './ActivityButtonGroup.scss';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, CustomInput
} from 'reactstrap';
import moment from 'moment';
import plusIcon from '../../images/icn-plus.svg';
import FormErrorMessage from '../FormErrorMessage';
import ActivityType from '../ActivityType';
import ModalConfirm from '../ModalConfirm';
import editIcon from '../../images/icn-edit.svg';
import DateInput from '../DateInput';
import { useAuth } from '../Auth';
import { fetchUsers } from '../../lib/fetch/users';
import { ButonTrash } from '../ButtonTrash/ButtonTrash';

function ActivityModal({ open, toggle, onSubmit, activity, type, onDeleteActivity } ) {
  const [t] = useTranslation('ACTIVITIES');
  const { id } = activity || {};

  const [{ token, profile } = {}] = useAuth();
  const user_id = activity?.user_id === undefined ? profile.id : activity.user_id;
  const [users, setUsers] = useState([]);
  const [isDone = activity?.done === 1, setIsDone] = useState();

  const [date = moment(activity?.date).format('YYYY-MM-DD'), setDate] = useState();
  const time = moment().format('HH:mm');
  
  const { handleSubmit, register, reset, errors } = useForm({
    defaultValues: {
      user_id: user_id || 0,
      date,
      time,
      description: activity?.description || '',
    }
  });
  
  const fetchOptions = useCallback(async () => {
    if (token) {
      try {
        const { data } = await fetchUsers({ token, limit: 1000 });
        setUsers(data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }
  }, [token]);

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line
  }, [token]);


  const prepareData = (values) => {
    const data = { ...values, date, done: isDone ? 1 : 0, user_id: parseInt(values.user_id) };
    onSubmit(data);
    reset({
      date: moment().format('YYYY-MM-DD'),
      time,
      user_id: user_id,
      description: '',
      done: false,
    })
  };

  return (
    <Modal isOpen={open} fade={false} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t(id ? 'UPDATE_ACTIVITY' : 'REGISTER_NEW_ACTIVITY')} <br />
        <ActivityType type={type} />
      </ModalHeader>
      <ModalBody>
        <Form inline className="m-b-30" onSubmit={handleSubmit(prepareData)}>
          <FormGroup className="mb-4" style={{ width: '50%' }}>
            <Label for="Date" className="mr-2">{t('DAY')}:</Label>
            <DateInput
              name="date"
              disablePopup={true}
              value={date}
              onChange={(v) => setDate(moment(v).format('YYYY-MM-DD'))}
            />
            <FormErrorMessage errors={errors} name="time" />
          </FormGroup>
          <FormGroup className="mb-4" style={{ width: '50%' }}>
            <Label for="Time" className="">{t('HOURS')}:</Label>
            <Input
              type="time"
              name="time"
              className="mr-4"
              defaultValue={moment().format('HH:mm')}
              innerRef={register}
            />
            <FormErrorMessage errors={errors} name="time" />
          </FormGroup>

          <FormGroup className="mb-4" style={{ width: '50%' }}>
            <Label className="mr-2">{t('COMMON:USER')}</Label>
            <CustomInput
              type="select"
              name="user_id"
              id="user_id"
              innerRef={register}
            >
              {
                users?.map(({ id, first_name, last_name }) => (
                  <option value={id} key={id}>{first_name} {last_name}</option>
                ))
              }
            </CustomInput>
          </FormGroup>
          
          <FormGroup className="mb-4" style={{ width: '50%' }}>
            <CustomInput
              className="done-checkbox"
              name="done"
              defaultChecked={isDone}
              type="checkbox"
              id="done"
              onChange={() => setIsDone(!isDone)}
              label={t("COMMON:SET_AS_DONE")}
            />
            <FormErrorMessage errors={errors} name="done" />
          </FormGroup>

          <FormGroup className="mb-4 form-description">
            <Label className="mr-2">{t('COMMON:DESCRIPTION')}</Label>
            <Input
              type="textarea"
              name="description"
              className="mx-sm-4"
              innerRef={register({
                required: t('COMMON:REQUIRED'),
              })}
            />
            <FormErrorMessage errors={errors} name="description" />
          </FormGroup>

          <ModalFooter className="cont-btn-modalform">
            {
              id && typeof onDeleteActivity === 'function' &&
              <ModalConfirm
                text={t('DELETE_REQUEST')}
                onConfirm={onDeleteActivity}
                className="user-delete-button"
              >
                <ButonTrash />
              </ModalConfirm>
            }
            <Button type="submit" color="primary">
              <img src={id ? editIcon : plusIcon} alt="" />
              {t(id ? 'UPDATE_ACTIVITY' : 'ADD_ACTIVITY')}
            </Button>
            <Button color="secondary" onClick={toggle}>{t('COMMON:CANCEL')}</Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ActivityModal;
