import React from 'react';
import useForm from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './ConfirmationModal.scss'

const ConfirmationModal = ({ show, onClose, onConfirm }) => {
    const [t] = useTranslation();
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        const { isAdded, isSigned } = data;
        if (isAdded && isSigned) {
            onConfirm({ isAdded, isSigned});
        }
    };

    return (
        <Modal isOpen={show} toggle={onClose}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader>
                    {t('DEAL_DETAIL:FORM_HEADING')}
                </ModalHeader>
                <ModalBody>
                    <FormGroup className='description'>{t('DEAL_DETAIL:FORM_DESCRIPTION')}</FormGroup>
                    <FormGroup check inline >
                        <Input
                            type="checkbox"
                            className="mr-2"
                            name="isAdded"
                            innerRef={register({ required: true })}
                            invalid={!!errors.isAdded}
                        />
                        <Label check for="isAdded" className='label'>{t('DEAL_DETAIL:FORM_MARK_ADDED')}</Label>
                    </FormGroup>
                        {errors.isAdded && (
                            <FormFeedback className='error'>
                                {t('DEAL_DETAIL:FORM_NO_ADDED')}
                            </FormFeedback>
                        )}
                    <FormGroup></FormGroup>
                    <FormGroup check inline >
                        <Input
                            type="checkbox"
                            className="mr-2"
                            name="isSigned"
                            innerRef={register({ required: true })}
                            invalid={!!errors.isSigned}
                        />                        
                        <Label check for="isSigned" className='label'>{t('DEAL_DETAIL:FORM_MARK_SIGNED')}</Label>
                    </FormGroup>                    
                    {errors.isSigned && (
                        <FormFeedback className='error'>
                            {t('DEAL_DETAIL:FORM_NO_SIGNED')}
                        </FormFeedback>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">{t('COMMON:CONFIRM')}</Button>
                    <Button color="secondary" onClick={onClose}>{t('COMMON:CANCEL')}</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;