import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import { ReactComponent as IconDeals } from '../../images/icn_offerte.svg'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import backIcon from '../../images/icn-back.svg'
import FrozenButton from '../../components/FrozenButton'
import ProcessStep from '../../components/ProcessStep'
import ActivityButtonGroup from '../../components/ActivityButtonGroup'
import CardActivities from '../../components/CardActivities'
import Footer from '../../components/Footer'
import BurgerMenu from '../../components/BurgerMenu'
import { useTranslation } from 'react-i18next'
import Proptypes from 'prop-types';
import { formatDate, formatNumber } from '../../lib/helpers/formatters';
import EditableTextarea from '../../components/EditableTextarea';
import EditableCurrency from '../../components/EditableCurrency';
import ModalConfirm from '../../components/ModalConfirm';
import Attachments from '../../components/Attachments';
import CostCenters from '../../components/CostCenters';
import DateInput from '../../components/DateInput';
import './DealDetailPresentational.scss';
import SearchModal from '../../components/SearchModal/SearchModal';
import ContactsAutosuggest from '../../components/ContactsAutosuggest';
import CompaniesAutosuggest from '../../components/CompaniesAutosuggest';
import UsersAutosuggest from '../../components/UsersAutosuggest';
// import { COST_CENTERS } from '../../components/CostCenters/constants';
import EditableLabel from '../../components/EditableLabel';
import { attachmentsLabels } from '../../constants/attachmentsLabels';
import { ButonTrash } from '../../components/ButtonTrash/ButtonTrash';
import { useAuth } from '../../components/Auth';
import { cutLength } from '../../lib/helpers/cutLength';

function DealDetailPresentational({ 
  history, 
  match, 
  deal, 
  activitiesData, 
  onSave, 
  onSaveActivity, 
  onDeleteActivity, 
  onDelete, 
  getDeal 
}) {
  const [t] = useTranslation('DEAL_DETAIL');
  const {
    closing_date,
    amount = 0,
    name = '',
    frozen,
    stage,
    date,
    company_id,
    company_name = '',
    contact_name = '',
    user_name = '',
    attachments,
    success_rate,
    id,
    cost_centers,
    // credit_type,
    // offer_type,
  } = deal;

  const [{profile}] = useAuth();

  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  // const [docLabel, setDocLabel] = useState(attachmentsLabels[0].value);

  const closingDate = formatDate(closing_date) || t('COMMON:NO_DATE');

  const handleSuccessRateChange = (e) => {
    const value = Math.min(100, Math.max(0, Number(e)));
    onSave({ success_rate: value });
  };

  // const handleAttachmentLabelChange = (e) => {
  //   setDocLabel(e.target.value);
  // }
  
  const handleStepSave = async (data) => {
    await onSave(data);
  };

  return (
    <div className="App">
      <Menu match={match} />

      <div className="corpo">
        <section>
          <Header
            title={t('COMMON:DEALS')}
            icon={<IconDeals fill='#FF6B38' />}
          />

          <div className="row m-b-30">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <Button className="btn-plus" onClick={() => history.goBack()}>
                <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
              </Button>
            </div>
            <div className="edit-element">
              {/*  <Button className="btn-trash">*/}
              {/*    <img src={editIcon} alt="" />*/}
              {/*  </Button>*/}
              <ModalConfirm text={t('DEAL_DETAIL:DELETE_REQUEST')} onConfirm={onDelete}>
                <ButonTrash />
              </ModalConfirm>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Card className="sales-card">
                <CardHeader>
                  <h3>
                    <EditableCurrency value={formatNumber(amount)} onSave={(v) => onSave({ amount: v })} />
                  </h3>
                  <h3>
                    <EditableTextarea value={name} onSave={(v) => onSave({ name: v })} />
                  </h3>
                  <div className="row">
                    <div className="col-12">
                      <p className="closing-date">{t('COMMON:CLOSING')}: <span>{closingDate}</span></p>
                      <FrozenButton frozen={frozen} onSave={onSave} />
                    </div>
                  </div>
                  <ProcessStep stage={stage} onSave={handleStepSave} />
                </CardHeader>
                <CardBody>
                  <ActivityButtonGroup onSave={onSaveActivity} />
                  <div className="cont-info">
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:CLOSING_DATE')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <DateInput
                          name="closing_date"
                          value={closing_date}
                          onChange={v => onSave({ closing_date: v })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:CREATED_AT')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <DateInput
                          name="date"
                          value={date}
                          onChange={v => onSave({ date: v })}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-4">
                        <p>{t('COMMON:COMPANY')}:</p>
                      </div>
                      <div className="col-8">
                        <div className="row-company-info">
                          {
                            company_id &&
                            <div className="search-value" onClick={() => company_id && history.push(`/company-detail/${company_id}/info`)}>
                              <b>{cutLength(company_name, 25)}</b>
                            </div>
                          }
                         
                              <SearchModal
                              title={t('DEAL_DETAIL:MODIFY_A_COMPANY')}
                              searchLabel={t('COMMON:SEARCH')}
                              open={openCompanyModal}
                              toggle={() => setOpenCompanyModal(false)}
                              onConfirm={result => result && result.id && onSave({ company_id: result.id })}
                              onDelete={() => onSave({ company_id: null, company_name: null })}
                            >
                              <CompaniesAutosuggest onChange={() => console.log('ok')}/>
                            </SearchModal>
                            <Button className='btn-plus btn-company' onClick={() => !openCompanyModal && setOpenCompanyModal(true)}>
                              {t('COMMON:SELECT_COMPANY')}
                            </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:CONTACT')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <p className="search-value" onClick={() => !openContactModal && setOpenContactModal(true)}>
                          {contact_name ? <b>{contact_name}</b> : <i>{t('MODIFY_A_CONTACT')}</i>}
                        </p>
                        <SearchModal
                          title={t('MODIFY_A_CONTACT')}
                          searchLabel={t('COMMON:SEARCH')}
                          open={openContactModal}
                          toggle={() => setOpenContactModal(false)}
                          onConfirm={result => result && result.id && onSave({ contact_id: result.id })}
                          onDelete={() => onSave({ contact_id: null, contact_name: null })}
                        >
                          <ContactsAutosuggest />
                        </SearchModal>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:USER')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <p className="search-value" onClick={() => !openUserModal && setOpenUserModal(true)}>
                          {user_name ? <b>{user_name}</b> : <i>{t('MODIFY_A_USER')}</i>}
                        </p>
                        <SearchModal
                          title={t('MODIFY_A_USER')}
                          searchLabel={t('COMMON:SEARCH')}
                          open={openUserModal}
                          toggle={() => setOpenUserModal(false)}
                          onConfirm={result => result && result.id && onSave({ user_id: result.id })}
                          onDelete={() => onSave({ user_id: null, user_name: null })}
                        >
                          <UsersAutosuggest />
                        </SearchModal>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('SUCCESS_RATE')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        
                        <div className="input">
                          <EditableLabel type="text" value={success_rate} onSave={(v) => handleSuccessRateChange(v)}>
                            {success_rate ? <b>{success_rate} %</b> : <i>{t('SUCCESS_RATE_INPUT')}</i>}
                          </EditableLabel>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:CREDIT_TYPE')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <CustomInput
                          type="select"
                          name="creditType"
                          id="creditType"
                          className="inline-select"
                          onChange={ev => onSave({ credit_type: ev.target.value })}
                          value={credit_type}
                        >
                          <option></option>
                          {
                            ['5', '10', '110'].map((value) => {
                              return <option value={value} key={value}>{value}</option>
                            })
                          }
                        </CustomInput>
                      </div>
                    </div> */}
                    {/* <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:OFFER_TYPE')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <CustomInput
                          type="select"
                          name="offerType"
                          id="offerType"
                          className="inline-select"
                          onChange={ev => onSave({ offer_type: ev.target.value })}
                          value={offer_type}
                        >
                          <option></option>
                          {
                            COST_CENTERS.map(({ name }) => {
                              return <option value={name} key={name}>{t(`COST_CENTERS:${name}`)}</option>
                            })
                          }
                        </CustomInput>
                      </div>
                    </div> */}
                    <CostCenters
                      dealId={id}
                      costCenters={cost_centers}
                      onSuccessCallback={getDeal}
                    />
                    <div className="section-attachments">
                      <div>
                      <p><b>{t('ATTACHMENTS')}</b></p>
                      </div>
                      <Attachments
                          attachments={attachments}
                          deal_id={id}
                          onSuccessCallback={getDeal}
                          currentAttachmentLabel={attachmentsLabels[0].value}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <CardActivities
                activitiesData={activitiesData}
                onSaveActivity={onSaveActivity}
                onDeleteActivity={onDeleteActivity}
                user_id={profile.id}
              />
            </div>
          </div>

        </section>
        <Footer />
      </div>

      <BurgerMenu />
    </div >
  );
}

DealDetailPresentational.propTypes = {
  activitiesData: Proptypes.arrayOf(Proptypes.shape({
    type: Proptypes.string,
    date: Proptypes.string,
    description: Proptypes.string,
    created_at: Proptypes.string,
    updated_at: Proptypes.string,
    deal_id: Proptypes.number,
    contact_id: Proptypes.number,
  })).isRequired,
  deal: Proptypes.shape({
    name: Proptypes.string,
    stage: Proptypes.string,
    frozen: Proptypes.number,
    amount: Proptypes.number,
    closing_date: Proptypes.string,
    created_at: Proptypes.string,
    updated_at: Proptypes.string,
    company_id: Proptypes.number,
    contact_id: Proptypes.number,
    contact_name: Proptypes.string,
    company_name: Proptypes.string,
    success_rate: Proptypes.number,
  }),
  history: Proptypes.shape({
    goBack: Proptypes.func.isRequired,
  }),
  onSave: Proptypes.func.isRequired,
  onDelete: Proptypes.func.isRequired,
};

export default withRouter(DealDetailPresentational);
