import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Input, Label, CustomInput } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import DateInput from '../DateInput';
import { COST_CENTERS } from '../CostCenters/constants';
import { ALL_STEPS } from '../ProcessStep/steps';
import { fetchUsers } from '../../lib/fetch/users';
import { useAuth } from '../Auth';
import fetchCompanies from '../../lib/fetch/companies';
import { fetchContacts } from '../../lib/fetch/contacts';
import { FILTERS } from '../../constants/filters';
const formatDate = date => moment(date).format('YYYY-MM-DD');

function Filters({ query = {}, onFilter, list } = {}) {
  const [t] = useTranslation();
  const [debouncedOnFilter] = useDebouncedCallback(value => onFilter(value), 800);
  const { from, to, search, stage, costCenter, offer_type, userId, companyId, contactId, success_rate } = query;
  const [{ token } = {}] = useAuth();

  const [USERS, setUsers] = useState([]);
  const [COMPANIES, setCompanies] = useState([]);
  const [CONTACTS, setContacts] = useState([]);

  if (!Array.isArray(list)) {
    console.warn('list prop should be an array')
  }

  const fetchOptions = useCallback(async () => {
    if (token) {
      try {
        const [usersResponse, companiesResponse, contactsResponse] = await Promise.all([
          fetchUsers({ token, limit: 1000 }),
          fetchCompanies({ token, limit: 1000 }), // HOW TO AVOID THE LIMIT?
          fetchContacts({ token, limit: 1000 }) // AGGIUNTO PER ERRORE / HOW TO AVOID THE LIMIT?
        ]);
        setUsers(usersResponse.data?.sort((a, b) => a.first_name.localeCompare(b.first_name)));
        setCompanies(companiesResponse.data);
        setContacts(contactsResponse.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }
  }, [token]);

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line
  }, []);

  return (
    <Form inline className="filter" onSubmit={(event) => event.preventDefault()}>
      {
        list?.includes(FILTERS.SEARCH) &&
        <FormGroup>
          <Label for="exampleSelect">{t('COMMON:FILTER_BY')}</Label>
          <Input
            type="text"
            name="search"
            id="search"
            className="mx-sm-4"
            onChange={ev => debouncedOnFilter({ search: ev.target.value, offset: 0 })}
            defaultValue={search}
            placeholder="Cerca..."
          />
        </FormGroup>
      }
      {
        list?.includes(FILTERS.COST_CENTER) &&
        <FormGroup>
          <Label>{t('COMMON:COST_CENTER')}</Label>
          <CustomInput
            type="select"
            name="costCenter"
            id="costCenter"
            className="mx-sm-4"
            onChange={ev => debouncedOnFilter({ costCenter: ev.target.value, offset: 0 })}
            defaultValue={costCenter}
          >
            <option></option>
            {
              COST_CENTERS.map(({ name }) => {
                return <option value={name} key={name}>{t(`COST_CENTERS:${name}`)}</option>
              })
            }
          </CustomInput>
        </FormGroup>
      }
      {
        list?.includes(FILTERS.STAGE) &&
        <FormGroup>
          <Label>{t('COMMON:STATE')}</Label>
          <CustomInput
            type="select"
            name="stage"
            id="stage"
            className="mx-sm-4"
            onChange={ev => debouncedOnFilter({ stage: ev.target.value, offset: 0 })}
            defaultValue={stage}
          >
            <option></option>
            {
              ALL_STEPS.map(step => {
                return <option value={step} key={step}>{t(`COMMON:DEALS_STATUSES_${step.toUpperCase()}`)}</option>
              })
            }
          </CustomInput>
        </FormGroup>
      }
      {
        list?.includes(FILTERS.FROM) &&
        <FormGroup>
          <Label for="Date">{t('COMMON:FROM')}</Label>
          <DateInput
            onChange={date => onFilter({ from: date && formatDate(date), offset: 0 })}
            disablePopup={true}
            value={from}
            className="mx-sm-4"
            name="from"
          />
        </FormGroup>
      }
      {
        list?.includes(FILTERS.TO) &&
        <FormGroup >
          <Label for="Time">{t('COMMON:TO')}</Label>
          <DateInput
            onChange={date => onFilter({ to: date && formatDate(date), offset: 0 })}
            disablePopup={true}
            value={to}
            className="mx-sm-4"
            name="to"
          />
        </FormGroup>
      }
      {
        list?.includes(FILTERS.OFFER_TYPE) &&
        <FormGroup>
          <Label>{t('COMMON:OFFER_TYPE')}</Label>
          <CustomInput
            type="select"
            name="offer_type"
            id="offer_type"
            className="mx-sm-4"
            onChange={ev => onFilter({ offer_type: ev.target.value })}
            defaultValue={offer_type}
          >
            <option></option>
            {
              COST_CENTERS.map(({ name }) => {
                return <option value={name} key={name}>{t(`COST_CENTERS:${name}`)}</option>
              })
            }
          </CustomInput>
        </FormGroup>
      }
      {
        list?.includes(FILTERS.USER_ID) &&
        <FormGroup>
          <Label>{t('COMMON:USER')}</Label>
          <CustomInput
            type="select"
            name="userId"
            id="userId"
            className="mx-sm-4"
            onChange={ev => onFilter({ userId: ev.target.value })}
            defaultValue={userId}
          >
            <option></option>
            {
              USERS?.map(({ id, first_name, last_name }) => {
                return <option value={id} key={id}>{first_name + ' ' + last_name}</option>
              })
            }
          </CustomInput>
        </FormGroup>
      }
      {
        list?.includes(FILTERS.COMPANY_ID) &&
        <FormGroup>
          <Label>{t('COMMON:COMPANY')}</Label>
          <CustomInput
            type="select"
            name="companyId"
            id="companyId"
            className="mx-sm-4"
            onChange={ev => onFilter({ companyId: ev.target.value })}
            defaultValue={companyId}
          >
            <option></option>
            {
              COMPANIES?.map(({ id, name }) => {
                return <option value={id} key={id}>{name}</option>
              })
            }
          </CustomInput>
        </FormGroup>
      }
      {
        list?.includes(FILTERS.CONTACT_ID) &&
        <FormGroup>
          <Label>{t('COMMON:CONTACT')}</Label>
          <CustomInput
            type="select"
            name="contactId"
            id="contactId"
            className="mx-sm-4"
            onChange={ev => onFilter({ contactId: ev.target.value })}
            defaultValue={contactId}
          >
            <option></option>
            {
              CONTACTS?.sort((a, b) => a.first_name.localeCompare(b.first_name))?.map(({ id, first_name, last_name }) => {
                return <option value={id} key={id}>{first_name + ' ' + last_name}</option>
              })
            }
          </CustomInput>
        </FormGroup>
      }
      {
        list?.includes(FILTERS.SUCCESS_RATE) &&
        <FormGroup>
          <Label for="exampleSelect">{t('COMMON:SUCCESS_PERCENTAGE')}</Label>
          <Input
            type="number"
            name="success_rate"
            id="success_rate"
            className="mx-sm-4"
            onChange={ev => debouncedOnFilter({ success_rate: ev.target.value, offset: 0 })}
            defaultValue={success_rate}
            placeholder="Cerca..."
          />
        </FormGroup>
      }
    </Form>
  );
}

Filters.propTypes = {
  query: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
};

export default Filters;
