import axios from 'axios';
import qs from 'qs';

export const fetchActivities = async ({
  token,
  dealId,
  contactId,
  userId,
  companyId,
  offset = 0,
  limit = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT,
  order,
  sort
} = {}) => {

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/activities?${qs.stringify({ dealId, userId, companyId, contactId, offset, limit, order, sort })}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const fetchActivitiesTodo = async ({
  token,
  order,
  sort
} = {}) => {

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/activities/todo?${qs.stringify({ order, sort })}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const upsertActivity = async ({ id, token, data = {} } = {}) => {

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/activities/${id}`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};


export const deleteActivity = async ({ id, token } = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/activities/${id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const setActivityDone = async ({ id, token, done = 1 } = {}) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/activities/${id}/done`,
      headers: {
        Authorization: token,
      },
      data: {
        done
      }
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};