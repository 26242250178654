import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, CustomInput,
} from 'reactstrap';
import FormErrorMessage from '../../components/FormErrorMessage';
import ModalConfirm from '../../components/ModalConfirm';
import useForm from 'react-hook-form';
import plusIcon from '../../images/icn-plus.svg';
import editIcon from '../../images/icn-edit.svg';
import { ButonTrash } from '../../components/ButtonTrash/ButtonTrash';

function UserModal({ history, open, toggle, user, onSubmit, onDeleteUser }, ref) {
  const [t] = useTranslation('USERS');
  const { handleSubmit, register, errors, reset } = useForm(user);
  const { id } = user || {};

  useImperativeHandle(ref, () => ({
    resetForm() {
      const { first_name = '', last_name = '', email = '', role = 'user' } = user || {}
      reset({ first_name, last_name, email, role })
    }
  }));

  return (
    <Modal isOpen={open} fade={false} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t(id ? 'BTN_UPDATE_USER' : 'BTN_ADD_USER')}
      </ModalHeader>
      <ModalBody>
        <Form className="m-b-35" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('FIRST_NAME')}</Label>
                <Input
                  type="text"
                  name="first_name"
                  innerRef={register({ required: t('COMMON:REQUIRED'), maxLength: { value: 45, message: 'Max 45 caratteri' } })}
                />
                <FormErrorMessage errors={errors} name="first_name" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('LAST_NAME')}</Label>
                <Input
                  type="text"
                  name="last_name"
                  innerRef={register({ required: t('COMMON:REQUIRED'), maxLength: { value: 45, message: 'Max 45 caratteri' } })}
                />
                <FormErrorMessage errors={errors} name="last_name" />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className={`col-sm-12 col-md-${id ? '12' : '6'}`}>
              <FormGroup>
                <Label>{t('EMAIL')}</Label>
                <Input
                  disabled={id}
                  type="email"
                  name="email"
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t('COMMON:INVALID_EMAIL')
                    }
                  })} />
                <FormErrorMessage errors={errors} name="email" />
              </FormGroup>
            </div>
            {
              !id &&
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>{t('PASSWORD')}</Label>
                  <Input
                    type="text"
                    name="password"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      minLength: { value: 6, message: 'Minimo 6 caratteri' },
                      maxLength: { value: 20, message: 'Massimo 20 caratteri' }
                    })}
                  />
                  <FormErrorMessage errors={errors} name="password" />
                </FormGroup>
              </div>
            }
          </div>
          <div className="row">
            <div className={`col-sm-12 col-md-${id ? '12' : '6'}`}>
              <FormGroup>
                <Label className='mr-2'>{t('ROLE')}</Label>
                <CustomInput
                  type="select"
                  id="role"
                  name="role"
                  innerRef={register({ required: t('COMMON:REQUIRED') })}
                >
                  <option value="user">Agente</option>
                  <option value="admin">Amministratore</option>
                </CustomInput>
                <FormErrorMessage errors={errors} name="role" />
              </FormGroup>
            </div>
          </div>          
          {
            id &&
            <div className="row">
              <div className={`col-sm-12 col-md-${id ? '12' : '6'}`}>              
                <Button className="btn-plus btn-company mb-4" onClick={() => history.push(`/deals?userId=${id}`)}>
                  {t('COMMON:USER_DETAIL')}
                </Button>
              </div>
            </div>
          }
          <ModalFooter className="cont-btn-modalform">
            {
              id &&
              <ModalConfirm
                text={t('DELETE_REQUEST')}
                onConfirm={() => onDeleteUser(id)}
                className="user-delete-button"
              >
                <ButonTrash />
              </ModalConfirm>
            }
            <Button type="submit" color="primary">
              <img src={id ? editIcon : plusIcon} alt="" />
              {t(id ? 'BTN_UPDATE_USER' : 'BTN_ADD_USER')}
            </Button>
            <Button color="secondary" onClick={toggle}>{t('COMMON:CANCEL')}</Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default forwardRef(UserModal);


