import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Modal, ModalBody, Spinner } from 'reactstrap';
import './Attachments.scss'
import { editAttachment } from '../../lib/fetch/attachments';
import { useAuth } from '../Auth';
import { useAlert } from 'react-alert';
import { AttachmentRow } from './AttachmentRow';

const AttachmentsPresentational = ({ attachments, deal_id, onChangeInputFile, uploading, onDownload, onDelete }) => {
  const [t] = useTranslation();
  const [{ token } = {}] = useAuth();
  const alert = useAlert();

  const onChangeSelectLabel = async (attachmentId, label) => {
    const { error } = await editAttachment({ token, deal_id, attachmentId, label });
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return
    }
    alert.success(t('COMMON:SAVE_SUCCESS'));
  }

  return (
    <>
      <div className="row">
        <div className="col-6">
          <p>{t('COMMON:LIST')}:</p>
        </div>
        <div className="col-6 text-right">
          <input
            id="attachments"
            type="file"
            className="attachment-input-file"
            onChange={onChangeInputFile}
            onClick={(event) => event.currentTarget.value = null}
          />
          <Label className="btn btn-add-attachment" htmlFor="attachments">
            {t('COMMON:ADD_ATTACHMENT')}
          </Label>
        </div>
      </div>
      {
        !attachments || attachments.length === 0 ?
          <div><small>{t('DEAL_DETAIL:NO_ATTACHMENTS')}</small></div>
          :
          attachments.map((attachment, key) => (
            <AttachmentRow onChangeSelectLabel={onChangeSelectLabel} key={key} attachment={attachment} onDownload={onDownload} onDelete={onDelete} />
          ))
      }

      <Modal
        isOpen={uploading}
        backdrop="static"
        keyboard={false}
        centered={true}
        contentClassName="attachment-content-modal"
      >
        <ModalBody>
          <div className="attachments-modal-spinner">
            <Spinner />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AttachmentsPresentational;