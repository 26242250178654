import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Input, Label, CustomInput } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import DateInput from '../DateInput';
import { COST_CENTERS } from '../../components/CostCenters/constants';
const formatDate = date => moment(date).format('YYYY-MM-DD');

function DashboardFilters({ query = {}, onFilter } = {}) {
  const [t] = useTranslation();
  const [debouncedOnFilter] = useDebouncedCallback(value => onFilter(value), 800);
  const { from, to, companyName, offer_type } = query;

  return (
    <Form inline className="filter" onSubmit={(event) => event.preventDefault()}>
      <FormGroup>
        <Label for="exampleSelect">{t('DASHBOARD:FILTER_BY_COMPANY')}</Label>
        <Input
          type="text"
          name="companyName"
          id="companyName"
          className="mx-sm-4"
          onChange={ev => debouncedOnFilter({ companyName: ev.target.value, offset: 0 })}
          defaultValue={companyName}
          placeholder={t('COMMON:FIND')}
        />
      </FormGroup>
      <FormGroup>
        <Label for="Date">{t('COMMON:FROM')}</Label>
        <DateInput
          onChange={date => onFilter({ from: date && formatDate(date), offset: 0 })}
          disablePopup={true}
          value={from}
          className="mx-sm-4"
          name="from"
        />
      </FormGroup>
      <FormGroup >
        <Label for="Time">{t('COMMON:TO')}</Label>
        <DateInput
          onChange={date => onFilter({ to: date && formatDate(date), offset: 0 })}
          disablePopup={true}
          value={to}
          className="mx-sm-4"
          name="to"
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('COMMON:OFFER_TYPE')}</Label>
        <CustomInput
          type="select"
          name="offerType"
          id="offerType"
          className="mx-sm-4"
          onChange={ev => onFilter({ offer_type: ev.target.value })}
          defaultValue={offer_type}
        >
          <option></option>
          {
            COST_CENTERS.map(({ name }) => {
              return <option value={name} key={name}>{t(`COST_CENTERS:${name}`)}</option>
            })
          }
        </CustomInput>
      </FormGroup>
    </Form>
  );
}

DashboardFilters.propTypes = {
  query: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
};

export default DashboardFilters;
