import React from 'react';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ActivitiesBadge from '../ActivitiesBadge';
import ActivityType from '../ActivityType';

const BadgeField = ({ value }) => (<ActivitiesBadge>{value}</ActivitiesBadge>);

const StageField = ({ value, data }) => {
  const [t] = useTranslation('COMMON');
  const text = value && t(`COMMON:DEALS_STATUSES_${value.toUpperCase()}`);
  const { frozen } = data || {};

  return (
    <div>
      <Badge pill={true} className={value}>{text}</Badge>
      {
        frozen ? <Badge pill={true} className="freezed-badge"><i className="far fa-snowflake" /> {t('FREEZED')}</Badge> : ''
      }
    </div>
  );
};

const CreditTypeField = ({ value, data }) => {
  const [t] = useTranslation('COMMON');
  const text = value && t(`COMMON:CREDIT_TYPES_${value.toUpperCase()}`);

  return (
    <Badge pill={true} className={value}>{text}</Badge>
  );
};

const OfferTypeField = ({ value, data }) => {
  const [t] = useTranslation('COMMON');
  const text = value && t(`COMMON:DEALS_TYPES_${value.toUpperCase()}`);

  return (
    <Badge pill={true} className={value}>{text}</Badge>
  );
};

const ActivityTypeField = ({ value }) => (<ActivityType type={value} />);

export {
  BadgeField,
  StageField,
  CreditTypeField,
  OfferTypeField,
  ActivityTypeField,
};
