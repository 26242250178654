import React, { useState } from 'react';
import { Badge, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Proptypes from 'prop-types';
import ActivityModal from '../ActivityButtonGroup/ActivityModal';
import CardActivityItem from './CardActivityItem';
import './CardActivities.scss'

function CardActivities({ activitiesData, onSaveActivity, onDeleteActivity, user_id }) {
  const [t] = useTranslation('DEAL_DETAIL');
  const [activeTab, setActiveTab] = useState('1');
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState();

  const toggleModal = () => setOpen(!open);

  const openModal = (activity = {}) => {
    setActivity(activity)
    toggleModal()
  }

  const getActivitiesList = (activities = [], filterFunc = () => true) => {
    const filtered = activities.filter(filterFunc);
    if (!filtered.length) {
      return (
        <p className="no-activities">{t(`COMMON:NO_ACTIVITIES`)}</p>
      )
    }

    return filtered.map((activity, key) => (
      <CardActivityItem
        key={key}
        activity={activity}
        openModal={openModal}
      />
    ))
  };

  const toDoActivities = getActivitiesList(activitiesData, ({ done }) => !done);
  const doneActivities = getActivitiesList(activitiesData, ({ done }) => done);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <div>

      <Card className="card-log">
        <CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                <Badge pill className="to-do">
                  {toDoActivities.length}
                </Badge>
                {t('COMMON:FUTURE_ACTIVITIES')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                {t('COMMON:PAST_ACTIVITIES')}
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {toDoActivities}
            </TabPane>

            <TabPane tabId="2">
              {doneActivities}
            </TabPane>

          </TabContent>
        </CardBody>
      </Card>

      <ActivityModal
        activity={activity}
        open={open}
        toggle={toggleModal}
        onSubmit={(values) => {
          onSaveActivity(values, activity.type, activity.id)
          toggleModal()
        }}
        onDeleteActivity={() => {
          onDeleteActivity(activity.id)
          toggleModal()
        }}
        type={activity && activity.type}
      />

    </div>
  )
}

CardActivities.propTypes = {
  activitiesData: Proptypes.arrayOf(Proptypes.shape({
    type: Proptypes.string,
    date: Proptypes.string,
    description: Proptypes.string,
    created_at: Proptypes.string,
    updated_at: Proptypes.string,
    deal_id: Proptypes.number,
    contact_id: Proptypes.number,
    contact_name: Proptypes.number,
  })).isRequired
}

export default CardActivities
