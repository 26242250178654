import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useQueryParams, NumberParam } from 'use-query-params';
import { useAuth } from '../../components/Auth';
import iot from '../../lib/fetch/iot';
import IotPresentational from './IotPresentational';
import './IoT.scss';

function Iot(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [iotData, setIotData] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation('COMMON');
  const [auth = {}] = useAuth();
  const { token } = auth;

  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });

  const { offset } = query;

  useEffect(() => {
    const fetchIoT = async (query) => {
      setIsLoading(true);
      const { error, data, headers } = await iot(query);

      if (error) {
        setIsLoading(false);
        setIotData([]);
        setTotal(0);
        alert.error(t('GENERIC_ERROR'));
        return;
      }

      setIsLoading(false);
      setIotData(data);
      setTotal(Number(headers['x-total-count']));
    };

    fetchIoT({ token, offset });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return (
    <IotPresentational
      deals={iotData}
      total={total}
      query={query}
      onFilter={setQuery}
      isLoading={isLoading}
      {...props}
    />
  );
}

export default Iot;
