import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Modal, ModalBody, Spinner } from 'reactstrap';
import { PriceListRow } from './PriceListRow';

const PriceListPresentational = ({ 
  priceList=[],
  title,
  status,
  onAdd,
  onDownload,
  onDelete 
}) => {
  const [t] = useTranslation();
  const acceptFileFormats = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

  return (
    <>
      <div className="row">
        <div className="col-6">
          <p><b>{title}:</b></p>
        </div>
        <div className="col-6 text-right">
          <input
            id="priceList"
            type="file"
            accept={acceptFileFormats}
            className="attachments-input-file"
            onChange={onAdd}
            onClick={(event) => event.currentTarget.value = null}
          />
          <Label className="btn btn-add-attachments" htmlFor="priceList">
            {t('PRODUCT_DETAIL:UPLOAD_FILE')}
          </Label>
        </div>
      </div>
      <div className="list">
        {
          !priceList || priceList.length === 0 ?
            <small>{t('DEAL_DETAIL:NO_ATTACHMENTS')}</small>
            :
            priceList.map((item, key) => (
              <PriceListRow key={key} priceList={item} onDownload={onDownload} onDelete={onDelete} />
            ))          
        }
      </div>

      <Modal
        isOpen={status}
        backdrop="static"
        keyboard={false}
        centered={true}
        contentClassName="attachments-content-modal"
      >
        <ModalBody>
          <div className="attachments-modal-spinner">
            <Spinner />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PriceListPresentational;