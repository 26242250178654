import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadPriceList, signedUrl, deletePriceList } from './../../lib/fetch/priceLists';
import PriceListPresentational from './PriceListPresentational';
import { useAuth } from './../Auth'
import { useAlert } from 'react-alert';
import './PriceLists.scss'

const PriceListAttachments = ({ priceList, product_id, contact_id, title, onSuccessCallback }) => {
  const [t] = useTranslation();
  const [{ token } = {}] = useAuth();
  const [isLoading, setLoading] = useState(false);
  const alert = useAlert();

  const onAdd = async (event) => {
    setLoading(true);

    const file = event.target.files[0];

    const validFormats = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    if (!validFormats.includes(file.type)) {
      alert.error(t('COMMON:INVALID_FILE_FORMAT'));
      setLoading(false);
      return;
    }

    const { error } = await uploadPriceList({ token, file, product_id, contact_id });
    
    setLoading(false);
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return
    }

    alert.success(t('COMMON:SAVE_SUCCESS'));
    if (typeof onSuccessCallback === 'function') {
      onSuccessCallback()
    }
  }

  const onDownload = async (priceListId, type) => {
    const { error, data } = await signedUrl({ token, priceListId, type })

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return
    }

    const { url } = data || {}
    window.open(url, '_blank')
  }

  const onDelete = async (priceListId) => {
    const { error } = await deletePriceList({ token, priceListId })

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return
    }

    alert.success(t('COMMON:DELETE_SUCCESS'))

    if (typeof onSuccessCallback === 'function') {
      onSuccessCallback()
    }
  }

  return (
    <PriceListPresentational
      priceList={priceList}    
      title={title}
      onAdd={onAdd}
      status={isLoading}
      onDownload={onDownload}
      onDelete={onDelete}
    />
  )
}

export default PriceListAttachments;