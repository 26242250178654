export const FILTERS = {
    SEARCH: 'search',
    COST_CENTER: 'costCenter',
    STAGE: 'stage',
    TO: 'to',
    FROM: 'from',
    OFFER_TYPE: 'offer_type',
    USER_ID: 'user_id',
    COMPANY_ID: 'company_id',
    CONTACT_ID: 'contact_id',
    SUCCESS_RATE: 'success_rate',
}