import React from 'react';
import './Menu.scss';
import LinkMenu from '../LinkMenu';

function Menu({ match }) {
  return (
    <div className="nav-desktop">
      <nav className='scrollable scroll-left'>
        <LinkMenu />
      </nav>
    </div>
  );
}

export default Menu;
