import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchUsers, saveUser, createAuthUser, createUser, deleteUser } from '../../lib/fetch/users';
import { useLoading } from '../../components/Loading'
import { useAuth } from '../../components/Auth';
import { useAlert } from 'react-alert';
import { StringParam, useQueryParams } from 'use-query-params';
import UsersPresentational from './UsersPresentational';

function Users(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [t] = useTranslation('USERS');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [query, setQuery] = useQueryParams({
    search: StringParam,
  });

  const { search } = query;

  const fetch = async () => {
    setIsLoading(true);
    const { error, data } = await fetchUsers({ token, search, limit: 100000 });

    if (error) {
      setIsLoading(false);
      setUsers([]);
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setIsLoading(false);
    setUsers(data);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onSave = async (id, values) => {
    showLoading();
    const { error } = await saveUser({ token, id, data: values });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    await fetch({ token });
    hideLoading();
    alert.success(t('COMMON:SAVE_SUCCESS'));
  };

  const onCreate = async (values) => {
    showLoading();
    const { email, password, first_name, last_name, role = 'user' } = values;

    const { error: createUserError } = await createUser({ data: { first_name, last_name, email, role }, token });

    if (createUserError) {
      const { response: { data: { message = '' } = {} } = {} } = createUserError;
      const errorMessage = message === 'user-exist' ? t('MESSAGE_ERROR_USER_EXIST') : t('COMMON:GENERIC_ERROR');

      hideLoading();
      alert.error(errorMessage);
      return;
    }

    const { error: createAuthUserError } = await createAuthUser(email, password);

    if (createAuthUserError) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    await fetch({ token });
    hideLoading();
  };

  const onDelete = async (id) => {
    showLoading();
    const { error } = await deleteUser({ id, token });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    await fetch({ token });
    hideLoading();
    alert.success(t('COMMON:DELETE_SUCCESS'));
  };

  return (
    <UsersPresentational
      onSaveUser={onSave}
      onCreateUser={onCreate}
      onDeleteUser={onDelete}
      onFilter={setQuery}
      query={query}
      isLoading={isLoading}
      users={users}
      {...props} />
  );
}

export default Users;
