import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Nav, NavItem, Button, Card, CardBody } from 'reactstrap';
import Menu from '../../components/Menu';
import './CompanyDetail.scss';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ReactComponent as IconCompanies } from '../../images/icn_aziende.svg';
import backIcon from '../../images/icn-back.svg';
import { Link, Route, Switch, Redirect } from "react-router-dom";
import CompanyInfo from './CompanyInfo';
import CompanyContacts from './CompanyContacts';
import ModalConfirm from '../../components/ModalConfirm';
import DealsTable from '../Deals/DealsTable';
import ContactsAutosuggest from '../../components/ContactsAutosuggest';
import SearchModal from '../../components/SearchModal/SearchModal';
import { ButonTrash } from '../../components/ButtonTrash/ButtonTrash';
import { KpisWrapper } from '../../components/KpisWrapper/KpisWrapper';
import { Kpi } from '../../components/Kpi/Kpi';
import { formatCurrency } from '../../lib/helpers/formatters';

function CompanyDetail({ match, history, location, company, onSave, onDelete, onAddContact, contacts,  total, deals, kpis, isLoading, onFilter, query }) {
  const [t] = useTranslation('COMPANYDETAIL');
  const { pathname = '' } = location
  const { url = '' } = match
  const tab = pathname.split('/').pop()
  const [openContactModal, setOpenContactModal] = useState(false);
  
  if (pathname === url) {
    return <Redirect to={`${match.url}/info`} />
  }

  const { name = '' } = company;
  const toggleModal = () => setOpenContactModal(!openContactModal);

  return (
    <div className="App">
      <Menu match={match} />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconCompanies fill='#FF6B38' />}
          />

          <div className="title-header">
            <div className="row m-b-30">
              <div className="col-sm-7">
                <h4>
                  <Button className="btn-plus" onClick={() => history.push('/companies')}>
                    <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
                  </Button>
                  <b>{name}</b>
                </h4>
              </div>
              {tab === 'contacts' && (
                <div className="col-sm-4 mr-3 text-right">
                  <Button className="btn-plus" onClick={toggleModal}>{t('CONTACTS:BTN_ADD_CONTACTS')}</Button>
                </div>
              )}
              <div className="edit-element">
                <ModalConfirm text={t('DELETE_REQUEST')} onConfirm={onDelete}>
                  <ButonTrash />
                </ModalConfirm>
              </div>
            </div>
          </div>

          <Nav tabs style={{ marginTop: '-30px' }}>
            <NavItem>
              <Link to={`${match.url}/info`} className={classnames({ active: tab === 'info', 'nav-link': true })}>
                {t('COMPANY_DATA')}
              </Link>
            </NavItem>
            <NavItem>
              <Link to={`${match.url}/contacts`} className={classnames({ active: tab === 'contacts', 'nav-link': true })}>
                {t('COMMON:CONTACTS')}
              </Link>
            </NavItem>
            <NavItem>
              <Link to={`${match.url}/deals`} className={classnames({ active: tab === 'deals', 'nav-link': true })}>
                {t('COMMON:DEALS')}
              </Link>
            </NavItem>
          </Nav>

          <Switch>
            <Route
              path={`${match.url}/info`}
              component={props => <CompanyInfo company={company} onSave={onSave} {...props} />}
            />
            <Route
              path={`${match.url}/contacts`}
              component={props => <CompanyContacts contacts={contacts} {...props} />}
            />
            <Route
              path={`${match.url}/deals`}
              component={props => (
                <Card>
                  <CardBody>
                  <KpisWrapper>
                    <Kpi title={t('COMMON:DEALS_TOTAL')} value={formatCurrency(kpis?.sum ?? 0)} />
                    <Kpi title={t('COMMON:SUCCESS_RATE')} value={kpis?.success_rate ?? 'N/A'} />
                  </KpisWrapper>
                    <DealsTable
                      deals={deals}
                      total={total}
                      query={query}
                      isLoading={isLoading}
                      onFilter={onFilter}
                      {...props}
                    />
                  </CardBody>
                </Card>
              )} />
          </Switch>

        </section>
        <Footer />
      </div>
      
      <SearchModal
        title={t('CONTACTS:BTN_ADD_CONTACTS')}
        searchLabel={t('COMMON:SEARCH')}
        open={openContactModal}
        toggle={toggleModal}
        onConfirm={result => result && result.id && onAddContact({ contact_id: result.id })}
        onDelete={() => toggleModal()}
      >
        <ContactsAutosuggest />
      </SearchModal>
    </div >
  );
}
export default CompanyDetail;
