import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import useForm from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import NumberFormat from 'react-number-format';
import FormErrorMessage from '../../components/FormErrorMessage';
import { VALIDATION } from '../../constants';
import { fetchUsers } from '../../lib/fetch/users';
import { useAuth } from '../../components/Auth';
import { formatNumber } from '../../lib/helpers/formatters';

function CompanyInfo({ company, onSave }) {

  const [t] = useTranslation('COMPANYDETAIL');
  const [{ token }] = useAuth();

  const prepareDefaultData = (company) => {
    const { annual_revenue = 0, annual_budget = 0, bank_credit = 0 } = company
    return Object.assign({}, company, {
      annual_budget: !annual_budget ? 0 : annual_budget,
      annual_revenue: !annual_revenue ? 0 : annual_revenue,
      bank_credit: !bank_credit ? 0 : bank_credit,
    })
  }

  const { getValues, setValue, register, errors, triggerValidation, watch } = useForm({ defaultValues: prepareDefaultData(company) });
  const annual_budget = watch('annual_budget');
  const annual_revenue = watch('annual_revenue');
  const bank_credit = watch('bank_credit');

  const [users, setUsers] = useState([]);
  const [user = company.user_id, setUser] = useState();

  const getUsers = async () => {
    const { error, data} = await fetchUsers({ token, limit: 1000 });
    if (error) {
      return;
    } else {
      setUsers(data);
    }
  }

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, []);

  // FIXME: validation is not working
  const currencyValidationRules = {
    min: { value: 0, message: 'Valore minimo: 0' },
    max: { value: 9999999999, message: 'Valore massimo: 9999999999' }
  };

  useEffect(() => {
    register({ name: 'annual_budget' }, currencyValidationRules);
    register({ name: 'annual_revenue' }, currencyValidationRules);
    register({ name: 'bank_credit' }, currencyValidationRules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);


  const onSubmit = async () => {
    const isValid = await triggerValidation();
    if (!isValid) return;
    const values = getValues();
    values.annual_budget = Number(values.annual_budget) || 0;
    values.annual_revenue = Number(values.annual_revenue) || null;
    values.bank_credit = Number(values.bank_credit) || null
    values.user_id = Number(user);
    console.log(values);
    onSave(values);
  };

  const [submit] = useDebouncedCallback(onSubmit, 700);


  return (
    <Card>
      <CardBody>
        <Form>
          <div className="row m-b-50">
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('LABEL_BUDGET')}</Label>
                <NumberFormat
                  className="form-control"
                  onValueChange={({ floatValue = 0 } = {}) => { setValue('annual_budget', floatValue); submit(); }}
                  autoFocus={false}
                  allowNegative={false}
                  suffix=" €"
                  decimalScale="2"
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  value={formatNumber(annual_budget, 2)}
                />
                <FormErrorMessage errors={errors} name="annual_budget" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('LABEL_BANK_BUDGET')}</Label>
                <NumberFormat
                  className="form-control"
                  onValueChange={({ floatValue = 0 } = {}) => { setValue('bank_credit', floatValue); submit(); }}
                  autoFocus={false}
                  allowNegative={false}
                  suffix=" €"
                  decimalScale="2"
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  value={formatNumber(bank_credit, 2)}
                />
                <FormErrorMessage errors={errors} name="bank_credit" />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('LABEL_COMPANY_NAME')} *</Label>
                <Input
                  onChange={submit}
                  type="text"
                  name="name"
                  innerRef={register({ required: t('COMMON:REQUIRED'), maxLength: { value: 45, message: 'Max 45 caratteri' } })}
                />
                <FormErrorMessage errors={errors} name="name" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('LABEL_VAT')}</Label>
                <Input
                  onChange={submit}
                  type="text"
                  name="vat"
                  innerRef={register({ maxLength: { value: 25, message: 'Max 25 caratteri' } })}
                />
                <FormErrorMessage errors={errors} name="vat" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('COMMON:EMAIL')}</Label>
                <Input
                  onChange={submit}
                  type="email"
                  name="email"

                  innerRef={register({
                    maxLength: { value: 45, message: 'Max 45 caratteri' },
                    pattern: {
                      value: /^$|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t('COMMON:INVALID_EMAIL')
                    },
                  })}
                />
                <FormErrorMessage errors={errors} name="email" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('COMMON:PHONE')}</Label>
                <Input
                  onChange={submit}
                  type="text"
                  name="phone"
                  innerRef={register({ maxLength: { value: 45, message: 'Max 45 caratteri' } })}
                />
                <FormErrorMessage errors={errors} name="phone" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('LABEL_REVENUE')}</Label>
                <NumberFormat
                  className="form-control"
                  onValueChange={({ floatValue = 0 } = {}) => { setValue('annual_revenue', floatValue); submit(); }}
                  autoFocus={false}
                  allowNegative={false}
                  suffix=" €"
                  decimalScale="2"
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  value={formatNumber(annual_revenue, 2)}
                />
                <FormErrorMessage errors={errors} name="annual_revenue" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label>{t('LABEL_STATE')}</Label>
                <Input
                  id="state"
                  type="select"
                  name="state"
                  innerRef={register}
                  onChange={submit}
                >
                  <option value="new">{t('STATE_NEW')}</option>
                  <option value="open">{t('STATE_OPEN')}</option>
                  <option value="in progress">{t('STATE_IN_PROGRESS')}</option>
                  <option value="open deal">{t('STATE_OPEN_DEAL')}</option>
                  <option value="unqualified">{t('STATE_UNQUALIFIED')}</option>
                  <option value="attempted_to_contract">{t('STATE_ATTEMPTED_TO_CONTRACT')}</option>
                  <option value="connected">{t('STATE_CONNECTED')}</option>
                  <option value="insolvent">{t('STATE_INSOLVENT')}</option>
                </Input>
                <FormErrorMessage errors={errors} name="state" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label>{t('LABEL_WEBSITE')}</Label>
                <Input
                  onChange={submit}
                  type="text"
                  name="website"
                  innerRef={register({ maxLength: { value: 45, message: 'Max 45 caratteri' } })}
                />
                <FormErrorMessage errors={errors} name="website" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label>{t('LABEL_TYPE')}</Label>
                <Input
                  id="type"
                  type="select"
                  name="type"
                  innerRef={register}
                  onChange={submit}
                >
                  <option value="client">{t('TYPE_CLIENT')}</option>
                  <option value="supplier">{t('TYPE_SUPPLIER')}</option>
                  <option value="client-supplier">{t('TYPE_CLIENT_SUPPLIER')}</option>
                </Input>
                <FormErrorMessage errors={errors} name="type" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label className="mr-2">{t('COMMON:USER')}</Label>
                <Input
                  type="select"
                  name="user_id"
                  id="user_id"
                  value={user}
                  onChange={(e) => {setUser(Number(e.target.value)); submit()}}>
                  <option value=''></option>
                  {
                    users?.map(({ id, first_name, last_name }) => (
                      <option value={id} key={id}>{first_name} {last_name}</option>
                    ))
                  }
                </Input>
                <FormErrorMessage errors={errors} name="address" />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label>{t('COMMON:ADDRESS')} ({`${VALIDATION.short_text} ${t('COMMON:CHARS')}`})</Label>
                <Input
                  onChange={submit}
                  type="text"
                  name="address"
                  innerRef={register({ maxLength: { value: VALIDATION.short_text, message: t('COMMON:CHAR_LIMIT_REACHED') } })}
                />
                <FormErrorMessage errors={errors} name="address" />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup>
                <Label>{t('COMMON:NOTES')}</Label>
                <Input
                  onChange={submit}
                  type="textarea"
                  name="notes"
                  innerRef={register({ maxLength: { value: 1000, message: 'Max 1000 caratteri' } })}
                />
                <FormErrorMessage errors={errors} name="notes" />
              </FormGroup>
            </div>
          </div>
        </Form>
      </CardBody>
    </Card>

  )
}

export default CompanyInfo;
