export const COST_CENTERS = [
  {
    name: 'products',
    color: '#FF5733'
  },
  {
    name: 'services',
    color: '#995D81'
  },
  {
    name: 'NATIV CASA',
    color: '#6689A1'
  },
  {
    name: 'NATIV AUTO',
    color: '#4299AD'
  },
  {
    name: 'NATIV BARCA',
    color: '#72A36F'
  },
  {
    name: 'NATIV PET',
    color: '#AB8F3A'
  },
  {
    name: 'NATIV PROFESSIONAL',
    color: '#AB3A3A'
  },
  {
    name: 'GREENNY',
    color: '#7C3AAB'
  },
  {
    name: 'NURA',
    color: '#3AAB88'
  },
]