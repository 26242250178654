import React from "react";
import trashIcon from '../../images/icn-trash.svg';
import './ButonTrash.scss'

export const ButonTrash = ({onClick}) => {

    return (
    <div className="btn-icon-trash" onClick={onClick}>
        <img src={trashIcon} alt="" />
    </div>
)}