import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './ProcessStep.scss';
import { ALL_STEPS } from './steps';
import ConfirmationModal from '../ConfirmationModal';

function ProcessStep({ stage, onSave }) {
	const [t] = useTranslation('PROCESS_STEP');
	const stageNumber = ALL_STEPS.indexOf(stage);

	const [showModal, setShowModal] = useState(false);
	const [selectedStage, setSelectedStage] = useState(stage);

	const handleStageChange = (newStage) => {
		if (newStage === ALL_STEPS[4]) {
			setSelectedStage(newStage);
			setShowModal(true);
		} else {
			onSave({ stage: newStage });
			onSave({ stage: newStage });
		}
	};

	const handleModalConfirm = (formData) => {
		console.log(formData);
		setShowModal(false);
		
		onSave({ stage: selectedStage, quotation_added: formData.isAdded ? 1 : 0, quotation_signed: formData.isSigned ? 1 : 0});
	};

	return (
		<div>
			<div className="root">
				<div className="container-processbar">
					<ul className="progressbar">
					{
						ALL_STEPS.map((step, index) => (
						<li
							key={index} className={stageNumber >= index ? 'active' : ''} 
							onClick={() => handleStageChange(ALL_STEPS[index])}
						>
							{t(`COMMON:DEALS_STATUSES_${step.toUpperCase()}`)}
						</li>
						))
					}
					</ul>
				</div>
			</div>
			<ConfirmationModal
				show={showModal}
				onClose={() => setShowModal(false)}
				onConfirm={handleModalConfirm}
			/>
		</div>
	);
}

ProcessStep.propTypes = {
	stage: Proptypes.string,
	onSave: Proptypes.func.isRequired,
};

export default ProcessStep;
