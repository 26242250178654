import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth } from '../../components/Auth';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../components/Loading';
import ProductDetailPresentational from './ProductDetailPresentational';
import { saveProduct, deleteProduct, fetchProduct } from '../../lib/fetch/products';

function ProductDetail({ match, history }) {
	const { params: { id } = {} } = match;

	const [t] = useTranslation();
	const [product, setProduct] = useState({});
	const [{ token } = {}] = useAuth();
	const alert = useAlert();
	const { showLoading, hideLoading } = useLoading();	

	const getProduct = async () => {
		const { error, data} = await fetchProduct({ token, id });	
		if (!error) {
			setProduct(data);
		}
	}

	useEffect(() => {
		getProduct()
		//eslint-disable-next-line
	  }, []);
	
	const onSave = async (values) => {
		const { error, data } = await saveProduct({ id, token, data: values });
	
		if (error) {
		  alert.error(t('COMMON:GENERIC_ERROR'));
		  return;
		}
		setProduct(data);
	}
	
	const onDelete = async () => {
		showLoading()
		const { error } = await deleteProduct({ token, id });
		hideLoading()
		
		if (error) {
			alert.error(t('COMMON:GENERIC_ERROR'));
			return;
		}

		history.push('/products');
		alert.success(t('COMMON:DELETE_SUCCESS'));
	}

	return (
		<ProductDetailPresentational
			history={history}
			product={product}
			onSave={onSave}
			onDelete={onDelete}
			getProduct={getProduct}
		/>
	);
}

export default withRouter(ProductDetail);
